import { Grid, Input } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Address = ({ tandemStatement, handleInputChange }) => {
  const { t } = useTranslation("tandemStatement");

  return (
    <>
      <Grid>
        <Grid.Col>
          <h3>{t("address")}</h3>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper required label={t("street")} size="md">
            <Input
              required
              placeholder={t("street")}
              value={
                tandemStatement.street !== null ? tandemStatement.street : ""
              }
              name="street"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper required label={t("postcode")} size="md">
            <Input
              required
              placeholder={t("postcode")}
              value={
                tandemStatement.postcode !== null
                  ? tandemStatement.postcode
                  : ""
              }
              name="postcode"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper required label={t("city")} size="md">
            <Input
              required
              placeholder={t("city")}
              value={tandemStatement.city !== null ? tandemStatement.city : ""}
              name="city"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper required label={t("country")} size="md">
            <Input
              required
              placeholder={t("country")}
              value={
                tandemStatement.country !== null ? tandemStatement.country : ""
              }
              name="country"
              onChange={handleInputChange}
            />
          </Input.Wrapper>{" "}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Address;
