import { Container, Divider, Title } from "@mantine/core";
import LatestStatements from "./LatestStatements";

const Statements = () => {
  return (
    <>
      <Container px="lg">
        <Title order={3}>Zadnje oddaje</Title>
        <LatestStatements type="new" />
        <Divider my="lg" />
        <Title order={3}>Zadnji podpisani</Title>
        <LatestStatements type="signed" />
      </Container>
    </>
  );
};

export default Statements;
