import { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Center,
  Divider,
  Loader,
  Modal,
  Group,
  LoadingOverlay,
} from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import {
  deleteUserStatement,
  getSingleStatement,
  updateUserStatement,
} from "../../../api/statements";
import { UserStatement, Category } from "../../../types";
import Personal from "../statement/sections/Personal";
import EmergencyContact from "../statement/sections/EmergencyContact";
import License from "../statement/sections/License";
import Equipment from "../statement/sections/Equipment";
import Signature from "../statement/sections/Signature";
import Instructor from "../statement/sections/Instructor";
import { useLocation, useNavigate } from "react-router-dom";

const EditStatement = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { state } = useLocation();
  const id = state.id;

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [statement, setStatement] = useState<UserStatement>({
    id: "",
    name: "",
    birthDate: "",
    parachutingLicenseNumber: "",
    category: Category.A,
    validUntil: "",
    doctorCerificateValidUntil: new Date(),
    numberOfJumps: 0,
    emergencyContactName: "",
    emergencyContactPhoneNumber: "",
    ownEquipment: "",
    mainParachuteModelAndSize: "",
    spareParachuteModelAndSize: "",
    spareParachuteValidUntil: new Date(),
    dateOfStatement: new Date(),
    placeOfStatement: "",
    statementSignature: "",
    leadInstructorConfirmed: "",
    leadInstructorName: "",
    leadInstructorSignature: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();

  const getStatement = async (token) => {
    const statement = await getSingleStatement(token, id);
    if (statement.leadInstructorName === "") {
      statement.leadInstructorName = "Aleš Debeljak";
    }
    setStatement(statement);
  };

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      getStatement(token);
    }
    getToken();
    setIsLoading(false);
  }, [getAccessTokenSilently]);

  const handleDelete = async (id) => {
    await deleteUserStatement(id, accessToken);
  };

  const handleConfirm = () => {
    handleDelete(statement.id);

    navigate(-1);
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setStatement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function handleSubmit(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    setButtonDisabled(true);
    const response = await updateUserStatement(
      accessToken,
      statement,
      statement.id
    );
    navigate(-1);
    return response;
  }

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} overlayBlur={2} />;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h2 style={{ textAlign: "center" }}>
          REGISTRACIJA in IZJAVA O ODGOVORNOSTI
        </h2>

        <Personal statement={statement} handleInputChange={handleInputChange} />

        <Divider size="md" my="md" />

        <EmergencyContact
          handleInputChange={handleInputChange}
          statement={statement}
        />

        <Divider size="lg" my="lg" />

        <License handleInputChange={handleInputChange} statement={statement} />

        <Equipment
          handleInputChange={handleInputChange}
          statement={statement}
        />

        <Divider size="lg" my="lg" />

        <Signature
          statement={statement}
          handleInputChange={handleInputChange}
        />

        <Divider size="sm" my="sm" />

        <Instructor
          statement={statement}
          handleInputChange={handleInputChange}
        />

        <Divider size="sm" my="sm" />
        <Group>
          <Button
            type="submit"
            color="orange"
            disabled={buttonDisabled ? true : false}
          >
            {buttonDisabled ? <Loader size="sm" /> : <p>Shrani izjavo</p>}
          </Button>
          <Button
            color={"red"}
            onClick={() => {
              setShowConfirmation(true);
            }}
          >
            Briši izjavo
          </Button>
        </Group>

        <Modal
          opened={showConfirmation}
          onClose={handleCancel}
          size="sm"
          title="Brišem izjavo?"
        >
          <Center>
            <Group>
              <Button key="Prekliči" onClick={handleCancel} color="red">
                Prekliči
              </Button>
              <Button key="Potrdi" onClick={handleConfirm}>
                Potrdi
              </Button>
            </Group>
          </Center>
        </Modal>
      </form>
    </>
  );
};

export default EditStatement;
