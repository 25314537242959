import { Input } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

const Personal = ({
  student,
  handleInputChange,
  handleDatePickerInputChange,
}) => {
  return (
    <>
      <Input.Wrapper label="Ime" size="md">
        <Input
          placeholder="Ime"
          value={student.name !== null ? student.name : ""}
          name="name"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Priimek" size="md">
        <Input
          placeholder="Priimek"
          value={student.surname !== null ? student.surname : ""}
          name="surname"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Email" size="md">
        <Input
          placeholder="Email"
          value={student.email !== null ? student.email : ""}
          name="email"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Telefon" size="md">
        <Input
          placeholder="Telefon"
          value={student.phone !== null ? student.phone : ""}
          name="phone"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Ulica" size="md">
        <Input
          placeholder="Ulica"
          value={student.street !== null ? student.street : ""}
          name="street"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Poštna št." size="md">
        <Input
          placeholder="Poštna št."
          value={student.postcode !== null ? student.postcode : ""}
          name="postcode"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Kraj" size="md">
        <Input
          placeholder="Kraj"
          value={student.city !== null ? student.city : ""}
          name="city"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Država" size="md">
        <Input
          placeholder="Država"
          value={student.country !== null ? student.country : ""}
          name="country"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <DatePickerInput
        placeholder="Izberi datum"
        label="Datum rojstva"
        name="birthDate"
        value={student.birthDate ? new Date(student.birthDate) : new Date()}
        onChange={(value) => handleDatePickerInputChange("birthDate", value)}
      />

      <Input.Wrapper label="Kraj rojstva" size="md">
        <Input
          placeholder="Kraj rojstva"
          value={student.birthPlace !== null ? student.birthPlace : ""}
          name="birthPlace"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Kontakt v sili" size="md">
        <Input
          placeholder="Kontakt v sili"
          value={
            student.emergencycontactName !== null
              ? student.emergencycontactName
              : ""
          }
          name="emergencycontactName"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Kontakt v sili - telefon" size="md">
        <Input
          placeholder="Kontakt v sili - telefon"
          value={
            student.emergencycontactPhone !== null
              ? student.emergencycontactPhone
              : ""
          }
          name="emergencycontactPhone"
          onChange={handleInputChange}
        />
      </Input.Wrapper>
    </>
  );
};

export default Personal;
