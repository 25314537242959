export interface UserStatement {
  id?: string;
  name: string;
  birthDate: string | undefined;
  parachutingLicenseNumber: string | undefined;
  category: Category;
  validUntil: string | undefined;
  doctorCerificateValidUntil: Date | null;
  numberOfJumps: number | undefined;
  emergencyContactName: string | undefined;
  emergencyContactPhoneNumber: string | undefined;
  ownEquipment: string | undefined;
  mainParachuteModelAndSize: string | undefined;
  spareParachuteModelAndSize: string | undefined;
  spareParachuteValidUntil: Date | null;
  dateOfStatement: Date | null;
  placeOfStatement: string | undefined;
  statementSignature: any | null;
  leadInstructorConfirmed: string;
  leadInstructorName: string;
  leadInstructorSignature: string | undefined;
}

export interface TandemStatementType {
  id?: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  country: string;
  legalGuardian: string;
  placeOfStatement: string;
  statementSignature: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export interface Student {
  id: string;
  name: string;
  surname: string;
  email: string | undefined;
  phone: string | undefined;
  street: string | undefined;
  postcode: string | undefined;
  city: string | undefined;
  country: string | undefined;
  birthDate: Date | null;
  birthPlace: string | undefined;
  emergencycontactName: string | undefined;
  emergencycontactPhone: string | undefined;
  status: string | undefined;
  sequential: string | undefined;
  dossier: string | undefined;
  medical: Date | null;
  started: Date | null;
  finished: Date | null;
  practical: Date | null;
  previousSchool: string | undefined;
  previousSchoolDossier: string | undefined;
  previousSchoolJumps: string | undefined;
  exam: Date | null;
  examCaa: Date | null;
  medicalCopy: string;
  emergencyProcedures: Date | null;
  exited: Date | null;
}

export interface NewStudent {
  name: string;
  surname: string;
  email: string;
}

export enum Category {
  A = "A",
  B = "B",
  C = "B",
  D = "B",
  S = "S",
}
