import { Grid, Input } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Personal = ({ tandemStatement, handleInputChange }) => {
  const { t } = useTranslation("tandemStatement");
  return (
    <>
      <Grid>
        <Grid.Col>
          <h3>{t("personal")}</h3>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper required label={t("name")} size="md">
            <Input required
              placeholder={t("name")}
              value={tandemStatement.name !== null ? tandemStatement.name : ""}
              name="name"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper required label={t("surname")} size="md">
            <Input required
              placeholder={t("surname")}
              value={
                tandemStatement.surname !== null ? tandemStatement.surname : ""
              }
              name="surname"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>

      <Input.Wrapper label={t("email")} size="md">
        <Input
          placeholder={t("email")}
          value={tandemStatement.email !== null ? tandemStatement.email : ""}
          name="email"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label={t("phone")} size="md">
        <Input
          placeholder={t("phone")}
          value={tandemStatement.phone !== null ? tandemStatement.phone : ""}
          name="phone"
          onChange={handleInputChange}
        />
      </Input.Wrapper>
    </>
  );
};

export default Personal;
