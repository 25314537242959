import { Grid, Input } from "@mantine/core";
import { useTranslation } from "react-i18next";

const EmergencyContact = ({ handleInputChange, statement }) => {
  const { t } = useTranslation("statement");
  return (
    <>
      <Grid>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper id="emer-name" required label={t("emName")} size="md">
            <Input
              id="emer-name"
              placeholder={t("name")}
              name="emergencyContactName"
              value={statement.emergencyContactName}
              onChange={handleInputChange}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper id="emer-tel" label={t("emPhone")} required size="md">
            <Input
              id="emer-tel"
              placeholder={t("phone")}
              name="emergencyContactPhoneNumber"
              value={statement.emergencyContactPhoneNumber}
              onChange={handleInputChange}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default EmergencyContact;
