import { useAuth0 } from "@auth0/auth0-react";
import { ActionIcon, Center } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    loginWithRedirect();
  }
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Center maw={400} h={100} mx="auto">
        <ActionIcon onClick={goBack} aria-label="Back" variant="transparent">
          <IconArrowLeft size="2rem" />
        </ActionIcon>
        Unauthorized
      </Center>
    </>
  );
};

export default Unauthorized;
