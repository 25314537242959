import { Checkbox, Flex, List, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Initial = (props) => {
  const { t } = useTranslation("tandemStatement");
  const list = props.list;
  const optional = props.optional ? false : true; // inverse, as we are passing to required

  const renderItems = () => {
    const textItemElements = [];
    for (let key in list) {
      if (list.hasOwnProperty(key)) {
        const value = list[key];
        const textItemElement = <List.Item key={key}>{value}</List.Item>;
        textItemElements.push(textItemElement);
      }
    }

    return (
      <>
        <List type="ordered" withPadding>
          {textItemElements}
        </List>
      </>
    );
  };

  return (
    <>
      <Text size="md">{props.text}</Text>
      {props.list && renderItems()}

      <Flex mih={50} gap="md" justify="flex-end" align="center" direction="row">
        <Checkbox required={optional} label={t("agree")} size="lg" />
      </Flex>
    </>
  );
};

export default Initial;
