import { Grid, Input, Text, Space, Button, Group } from "@mantine/core";
import SignatureCanvas from "react-signature-canvas";
import { DatePickerInput } from "@mantine/dates";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Signature = ({ handleInputChange, statement }) => {
  let sigPad: any | null = {};

  const clearSignatureHandler = () => {
    sigPad.clear();
  };

  useEffect(() => {
    sigPad.fromDataURL(statement.statementSignature, {
      width: 450,
      height: 300,
    });
  });

  const { t } = useTranslation("statement");

  return (
    <>
      <Grid>
        <Grid.Col>
          <h3>{t("signature")}</h3>
        </Grid.Col>
        <Grid.Col lg={4} md={4} sm={12} xs={12}>
          <DatePickerInput
            placeholder={t("pickDate")}
            label={t("date")}
            required
            name="dateOfStatement"
            value={new Date(statement.dateOfStatement)}
            onChange={(value: Date) => {
              handleInputChange({
                target: { name: "dateOfStatement", value },
              });
            }}
          />
        </Grid.Col>
        <Grid.Col lg={4} md={4} sm={12} xs={12}>
          <Input.Wrapper id="kraj" required label="Kraj:" size="md">
            <Input
              id="kraj"
              placeholder={t("placeOfStatement")}
              name="placeOfStatement"
              value={statement.placeOfStatement}
              onChange={handleInputChange}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col lg={8} md={8} sm={12} xs={12}>
          <Text>{t("signature")}</Text>
          <Group>
            <SignatureCanvas
              penColor="black"
              backgroundColor="lightgray"
              ref={(ref) => {
                sigPad = ref;
              }}
              clearOnResize={true}
              name="statementSignature"
              canvasProps={{ width: 450, height: 300, className: "sigCanvas" }}
              onEnd={({ srcElement }) => {
                if (srcElement instanceof HTMLCanvasElement) {
                  const value = srcElement.toDataURL();
                  handleInputChange({
                    target: { name: "statementSignature", value },
                  });
                }
              }}
            />

            <Button style={{ marginTop: 25 }} onClick={clearSignatureHandler}>
              {t("clearSignature")}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Space h="md" />
    </>
  );
};

export default Signature;
