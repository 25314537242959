import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllTandemStatements } from "../../../api/tandemStatements";
import { MantineReactTable } from "mantine-react-table";

export const TandemStatements = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [tandemStatements, setTandemStatements] = useState([]);
  const navigate = useNavigate();

  const getTandemStatements = async (token: string) => {
    setTandemStatements(await getAllTandemStatements(token));
  };

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      getTandemStatements(token);
    }
    getToken();
  }, [getAccessTokenSilently]);

  const columns = useMemo(
    () => [
      {
        header: "Ime",
        accessorKey: "name",
      },
      {
        header: "Priimek",
        accessorKey: "surname",
      },
    ],
    []
  );

  return (
    <>
      <MantineReactTable
        columns={columns}
        data={tandemStatements}
        mantineTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate("/tandems/statement-details", { state: row.original });
          },
          sx: {
            cursor: "pointer",
          },
        })}
        //options
        enablePagination={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableRowSelection={false}
        enableColumnOrdering={false}
        enableGlobalFilter={true}
      />
    </>
  );
};
