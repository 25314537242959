import { useAuth0 } from "@auth0/auth0-react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { createStudent, getAllStudents } from "../../api/students";
import { MantineReactTable } from "mantine-react-table";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Group, Input, Modal } from "@mantine/core";
import { NewStudent } from "../../types";
import { useDisclosure } from "@mantine/hooks";

const Students = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [newStudent, setNewStudent] = useState<NewStudent>({
    name: "",
    surname: "",
    email: "",
  });

  const getStudents = async (token) => {
    setStudents(await getAllStudents(token));
  };
  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      await getStudents(token);
    }
    getToken();
  }, [getAccessTokenSilently]);

  const columns = useMemo(
    () => [
      {
        header: "Ime",
        accessorKey: "name",
      },
      {
        header: "Priimek",
        accessorKey: "surname",
      },
    ],
    []
  );

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setNewStudent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function handleSubmit(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault();

    const response = await createStudent(accessToken, newStudent);
    close();
    getStudents(accessToken);
    return response;
  }

  return (
    <>
      <MantineReactTable
        columns={columns}
        data={students}
        mantineTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate("/student-details", { state: row.original });
          },
          sx: {
            cursor: "pointer",
          },
        })}
        //options
        enablePagination
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableRowSelection={false}
        enableColumnOrdering={false}
        enableGlobalFilter
      />
      <Divider size="lg" my="lg" />
      <Button onClick={open} key="Dodaj učenca">
        Dodaj učenca
      </Button>

      <Modal opened={opened} onClose={close} title="Dodaj novega učenca">
        <form onSubmit={handleSubmit}>
          <Input.Wrapper label="Ime" size="md">
            <Input
              placeholder="Ime"
              value={newStudent.name}
              name="name"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
          <Input.Wrapper label="Priimek" size="md">
            <Input
              placeholder="Priimek"
              value={newStudent.surname}
              name="surname"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
          <Input.Wrapper label="Email" size="md">
            <Input
              placeholder="Email"
              value={newStudent.email}
              name="email"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
          <Divider size="sm" my="sm" />
          <Group>
            <Button type="submit" color="blue">
              <p>Dodaj</p>
            </Button>
            <Button color={"red"} onClick={close}>
              Prekliči
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default Students;
