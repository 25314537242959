import { Button, Center, FileInput, Group, Modal, rem } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useState } from "react";
import {
  deleteStudentMedical,
  getSingleStudentMedical,
  saveStudentMedical,
} from "../../../../api/students";

const Medical = ({
  student,
  handleDatePickerInputChange,
  getStudent,
  token,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = async () => {
    await deleteStudentMedical(token, student.id);
    getStudent(token);
    setShowConfirmation(false);
  };

  const handleDownload = async () => {
    const url = await getSingleStudentMedical(token, student.id);

    // Trigger file download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileChange = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    await saveStudentMedical(token, formData, student.id);
    getStudent(token);
  };

  return (
    <>
      <DatePickerInput
        placeholder="Izberi datum"
        label="Zdravniško velja do"
        name="medical"
        value={student.medical ? new Date(student.medical) : null}
        onChange={(value) => handleDatePickerInputChange("medical", value)}
      />
      {!student.medicalCopy && (
        <FileInput
          label="Zdravniško"
          placeholder="Naloži zdravniško"
          onChange={(file) => handleFileChange(file)}
          name="medicalCopy"
        />
      )}
      {student.medicalCopy && (
        <>
          <Group spacing="xl">
            <Button
              styles={() => ({
                root: {
                  marginTop: rem(20),
                },
              })}
              color="green"
              onClick={handleDownload}
            >
              Prenesi zdravniško
            </Button>
            <Button
              styles={() => ({
                root: {
                  marginTop: rem(20),
                },
              })}
              color={"red"}
              onClick={() => {
                setShowConfirmation(true);
              }}
            >
              Izbriši zdravniško
            </Button>
          </Group>
          <Modal
            opened={showConfirmation}
            onClose={handleCancel}
            size="sm"
            title="Brišem zdravniško?"
          >
            <Center>
              <Group>
                <Button key="Prekliči" onClick={handleCancel} color="red">
                  Prekliči
                </Button>
                <Button key="Potrdi" onClick={handleConfirm}>
                  Potrdi
                </Button>
              </Group>
            </Center>
          </Modal>
        </>
      )}
    </>
  );
};

export default Medical;
