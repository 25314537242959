import {
  Group,
  Avatar,
  Text,
  Box,
  useMantineTheme,
  rem,
  Button,
  Accordion,
} from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";

export function User() {
  const theme = useMantineTheme();
  const { user, isAuthenticated } = useAuth0();
  const { logout } = useAuth0();

  return (
    isAuthenticated && (
      <Box
        sx={{
          paddingTop: theme.spacing.sm,
          borderTop: `${rem(1)} solid ${theme.colors.gray[2]}`,
        }}
      >
        <Group>
          <Avatar src={user.picture} radius="xl" />

          <Accordion
            styles={{ control: { paddingLeft: "0" } }}
            variant="filled"
          >
            <Accordion.Item value="logout">
              <Accordion.Control>
                <Text size="sm" weight={500}>
                  {user.name}
                </Text>
                <Text color="dimmed" size="xs">
                  {user.email}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Button px={0} size="xs" onClick={() => logout()} color="dark">
                  Odjavi
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Group>
      </Box>
    )
  );
}
