import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Center,
  Divider,
  Group,
  Loader,
  Modal,
  Tabs,
} from "@mantine/core";
import { useEffect, useState, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSingleStudent,
  updateStudent,
  deleteStudent,
  deleteStudentMedical,
} from "../../../api/students";
import { Student } from "../../../types";
import Medical from "./sections/Medical";
import Personal from "./sections/Personal";
import School from "./sections/School";

const StudentDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { state } = useLocation();
  const id = state.id;
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [student, setStudent] = useState<Student>({
    id: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    street: "",
    postcode: "",
    city: "",
    country: "",
    birthDate: new Date(),
    birthPlace: "",
    emergencycontactName: "",
    emergencycontactPhone: "",
    status: "",
    sequential: "",
    dossier: "",
    medical: new Date(),
    started: new Date(),
    finished: new Date(),
    practical: new Date(),
    previousSchool: "",
    previousSchoolDossier: "",
    previousSchoolJumps: "",
    exam: new Date(),
    examCaa: new Date(),
    medicalCopy: "",
    emergencyProcedures: new Date(),
    exited: new Date(),
  });

  const handleCancel = () => {
    // Do nothing
    setShowConfirmation(false);
  };

  const handleConfirm = async () => {
    await handleDelete(student.id);
    navigate(-1);
    setShowConfirmation(false);
  };

  const handleDelete = async (id) => {
    const promises = [];

    if (student.medicalCopy) {
      promises.push(deleteStudentMedical(accessToken, student.id));
    }

    promises.push(deleteStudent(id, accessToken));
    await Promise.all(promises);
  };

  const getStudent = async (token) => {
    const student = await getSingleStudent(token, id);
    setStudent(student);
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setStudent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleDatePickerInputChange(name: string, value: Date) {
    const event = {
      target: {
        name,
        value: value.toISOString(),
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleInputChange(event);
  }

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      getStudent(token);
    }
    getToken();
  }, [getAccessTokenSilently]);

  async function handleSubmit(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    setButtonDisabled(true);

    const response = await updateStudent(accessToken, student, student.id);
    navigate(-1);
    return response;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Tabs
          styles={() => ({
            root: {
              minHeight: "850px",
            },
          })}
          variant="outline"
          defaultValue="personal"
        >
          <Tabs.List>
            <Tabs.Tab value="personal">Osebni podatki</Tabs.Tab>
            <Tabs.Tab value="school">Šola</Tabs.Tab>
            <Tabs.Tab value="medical">Zdravniški</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="personal" pt="xs">
            <Personal
              student={student}
              handleInputChange={handleInputChange}
              handleDatePickerInputChange={handleDatePickerInputChange}
            />
          </Tabs.Panel>

          <Tabs.Panel value="school" pt="xs">
            <School
              student={student}
              handleInputChange={handleInputChange}
              handleDatePickerInputChange={handleDatePickerInputChange}
            />
          </Tabs.Panel>

          <Tabs.Panel value="medical" pt="xs">
            <Medical
              token={accessToken}
              getStudent={getStudent}
              student={student}
              handleDatePickerInputChange={handleDatePickerInputChange}
            />
          </Tabs.Panel>
        </Tabs>

        <Divider size="lg" my="lg" />

        <Group spacing="xl">
          <Button
            type="submit"
            color="blue"
            disabled={buttonDisabled ? true : false}
          >
            {buttonDisabled ? <Loader size="sm" /> : <p>Shrani</p>}
          </Button>
          <Button
            color={"red"}
            onClick={() => {
              setShowConfirmation(true);
            }}
          >
            Izbriši učenca
          </Button>
        </Group>

        <Modal
          opened={showConfirmation}
          onClose={handleCancel}
          size="sm"
          title="Brišem učenca?"
        >
          <Center>
            <Group>
              <Button key="Prekliči" onClick={handleCancel} color="red">
                Prekliči
              </Button>
              <Button key="Potrdi" onClick={handleConfirm}>
                Potrdi
              </Button>
            </Group>
          </Center>
        </Modal>
      </form>
    </>
  );
};

export default StudentDetails;
