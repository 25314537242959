import { Grid, Input, Radio, Divider, NumberInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useTranslation } from "react-i18next";

const License = ({ handleInputChange, statement }) => {
  const { t } = useTranslation("statement");
  return (
    <>
      <Grid>
        <Grid.Col>
          <h3>{t("license")}</h3>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Radio.Group
            label={t("category")}
            size="md"
            required
            value={statement.category}
            onChange={(value) => {
              handleInputChange({
                target: { name: "category", value },
              });
            }}
            name="category"
          >
            <Radio value="A" label="A" />
            <Radio value="B" label="B" />
            <Radio value="C" label="C" />
            <Radio value="D" label="D" />
            <Radio value="S" label="Student" />
          </Radio.Group>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <NumberInput
            defaultValue={statement.numberOfJumps}
            placeholder={t("noOfJumps")}
            label={t("noOfJumps")}
            required
            name="numberOfJumps"
            value={statement.numberOfJumps}
            onChange={(value) => {
              handleInputChange({
                target: { name: "numberOfJumps", value },
              });
            }}
          />
        </Grid.Col>

        {statement.category !== "S" && (
          <>
            <Grid.Col lg={6} md={6} sm={12} xs={12}>
              <Input.Wrapper
                id="st-pad-dovo"
                required
                label={t("licenseNumber")}
                size="md"
              >
                <Input
                  id="st-pad-dovo"
                  placeholder={t("licenseNumber")}
                  name="parachutingLicenseNumber"
                  value={statement.parachutingLicenseNumber}
                  onChange={handleInputChange}
                />
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col lg={6} md={6} sm={12} xs={12}>
              <DatePickerInput
                placeholder={t("pickDate")}
                label={t("licenseValid")}
                required
                value={
                  statement.validUntil !== ""
                    ? new Date(statement.validUntil)
                    : new Date()
                }
                onChange={(value: Date) => {
                  handleInputChange({
                    target: { name: "validUntil", value },
                  });
                }}
              />
            </Grid.Col>
          </>
        )}
      </Grid>

      <Divider size="md" my="md" />
    </>
  );
};

export default License;
