import axios from "axios";
import { UserStatement } from "../types";
import { debeliSignature } from "../components/statements/details/ASignature";

const BASE_URI = process.env.REACT_APP_BASE_API_URI || "http://localhost:3000";

export const getAllUserStatements = async (
  token: string | null
): Promise<UserStatement[]> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/statement`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getLatestUserStatements = async (
  token: string | null
): Promise<UserStatement[]> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/statement/latest`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getLatestSignedStatements = async (
  token: string | null
): Promise<UserStatement[]> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/statement/latest/signed`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getSingleStatement = async (
  token: string | null,
  id: string | undefined
): Promise<UserStatement> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/statement/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const confirmUserStatement = async (
  token: string | null,
  id: string | undefined
): Promise<UserStatement> => {
  const statement = await getSingleStatement(token, id);
  statement.leadInstructorSignature = debeliSignature;
  const confirmedStudent = await updateUserStatement(token, statement, id);
  return confirmedStudent;
};

export const updateUserStatement = async (
  token: string | null,
  payload: UserStatement,
  id: string | undefined
): Promise<UserStatement> => {
  const response = await axios({
    method: "PATCH",
    url: `${BASE_URI}/v1/statement/${id}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const postUserStatement = async (payload: any): Promise<number> => {
  const response = await axios({
    method: "POST",
    url: `${BASE_URI}/v1/statement`,
    data: payload,
  });

  return response.status;
};

export const deleteUserStatement = async (id: string, token: string | null) => {
  const response = await axios({
    method: "DELETE",
    url: `${BASE_URI}/v1/statement/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.status;
};
