import { useState } from "react";
import SignatureModule from "../../../global/SignatureModule";
import { Grid, Group, Input, Radio } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Legal = ({ tandemStatement, handleInputChange }) => {
  const { t } = useTranslation("tandemStatement");
  const [isMinor, setIsMinor] = useState(false);
  return (
    <>
      <Grid>
        <Grid.Col>
          <h3>{t("legal")}</h3>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Radio.Group
            withAsterisk
            onChange={(value) => setIsMinor(value === "true")}
            label={t("minorQuestion")}
            value={isMinor.toString()}
          >
            <Group mt="xs">
              <Radio value="true" label={t("yes")} />
              <Radio value="false" label={t("no")} />
            </Group>
          </Radio.Group>
        </Grid.Col>

        {isMinor && (
          <Grid.Col lg={6} md={6} sm={12} xs={12}>
            <Input.Wrapper label={t("legalGuardian")} size="md">
              <Input
                placeholder={t("legalGuardian")}
                value={
                  tandemStatement.legalGuardian !== null
                    ? tandemStatement.legalGuardian
                    : ""
                }
                name="legalGuardian"
                onChange={handleInputChange}
              />
            </Input.Wrapper>
          </Grid.Col>
        )}

        <Grid.Col>
          <SignatureModule
            signature={tandemStatement.statementSignature}
            handleInputChange={handleInputChange}
          />
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper required label={t("placeOfStatement")} size="md">
            <Input
              placeholder={t("placeOfStatement")}
              required
              value={
                tandemStatement.placeOfStatement !== null
                  ? tandemStatement.placeOfStatement
                  : ""
              }
              name="placeOfStatement"
              onChange={handleInputChange}
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Legal;
