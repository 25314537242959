import {
  Alert,
  Button,
  Center,
  Divider,
  Loader,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import classes from "./Statement.module.css";
import { AlertCircle } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import Texts from "./sections/Texts";
import { ChangeEvent, useState } from "react";
import { Category, UserStatement } from "../../../types";
import Personal from "./sections/Personal";
import EmergencyContact from "./sections/EmergencyContact";
import License from "./sections/License";
import Equipment from "./sections/Equipment";
import Signature from "./sections/Signature";
import { useNavigate } from "react-router-dom";
import { postUserStatement } from "../../../api/statements";

const Statement = () => {
  const { t, i18n } = useTranslation("statement");
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const [statement, setStatement] = useState<UserStatement>({
    name: "",
    birthDate: new Date().toDateString(),
    parachutingLicenseNumber: "",
    category: Category.S,
    validUntil: new Date().toDateString(),
    doctorCerificateValidUntil: new Date(),
    numberOfJumps: 0,
    emergencyContactName: "",
    emergencyContactPhoneNumber: "",
    ownEquipment: "false",
    mainParachuteModelAndSize: "",
    spareParachuteModelAndSize: "",
    spareParachuteValidUntil: new Date(),
    dateOfStatement: new Date(),
    placeOfStatement: "Šentvid pri Stični",
    statementSignature: "",
    leadInstructorConfirmed: "PENDING",
    leadInstructorName: "",
    leadInstructorSignature: "",
  });

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setStatement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setButtonDisabled(true);

    try {
      await postUserStatement(statement);
      navigate("/statement-success");
    } catch (error) {
      setButtonDisabled(false);
      window.scrollTo(0, 0);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  return (
    <div className={classes.main_container}>
      <Title order={1} style={{ marginTop: 10, textAlign: "center" }}>
        Prosti
        <Text color="orange" inherit component="span">
          pad
        </Text>
      </Title>

      <Paper shadow="xl" p="xl" style={{ marginTop: 10, marginBottom: 50 }}>
        <Center>
          {i18n.language === "sl" && (
            <Button color="orange" onClick={() => handleLanguageChange("en")}>
              English
            </Button>
          )}
          {i18n.language === "en" && (
            <Button color="orange" onClick={() => handleLanguageChange("sl")}>
              Slovensko
            </Button>
          )}
        </Center>
        <form onSubmit={handleSubmit}>
          <h2 style={{ textAlign: "center" }}>{t("title")} </h2>
          {showError && (
            <Alert icon={<AlertCircle size={16} />} title="Napaka" color="red">
              {t("error")}
            </Alert>
          )}
          <Personal
            statement={statement}
            handleInputChange={handleInputChange}
          />

          <Divider size="md" my="md" />

          <EmergencyContact
            handleInputChange={handleInputChange}
            statement={statement}
          />

          <Divider size="lg" my="lg" />

          <License
            handleInputChange={handleInputChange}
            statement={statement}
          />

          <Equipment
            handleInputChange={handleInputChange}
            statement={statement}
          />

          <Divider size="lg" my="lg" />

          <Texts />

          <Divider size="md" my="md" />

          <Signature
            statement={statement}
            handleInputChange={handleInputChange}
          />

          <Button
            type="submit"
            color="orange"
            disabled={buttonDisabled ? true : false}
          >
            {buttonDisabled ? <Loader size="sm" /> : <p>{t("submit")}</p>}
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default Statement;
