import { Button, Divider, Loader } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import {
  getLatestUserStatements,
  confirmUserStatement,
} from "../../api/statements";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { MantineReactTable } from "mantine-react-table";

const QuickStatements = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [statements, setStatements] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const getStatements = async (token) => {
    setStatements(await getLatestUserStatements(token));
  };

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      getStatements(token);
    }
    getToken();
  }, [getAccessTokenSilently]);

  const columns = useMemo(
    () => [
      {
        header: "Ime in priimek",
        accessorKey: "name",
      },
    ],
    []
  );

  const handleButtonClick = async () => {
    setButtonDisabled(true);
    const selectedIds = Object.keys(rowSelection);

    for (const id of selectedIds) {
      await confirmUserStatement(accessToken, id);
    }
    await getStatements(accessToken);
    setButtonDisabled(false);
  };

  return (
    <>
      <MantineReactTable
        columns={columns}
        data={statements}
        mantineTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate("/statement-details", { state: row.original });
          },
          sx: {
            cursor: "pointer",
          },
        })}
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
        //options
        enablePagination={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableRowSelection
        enableColumnOrdering={false}
        enableGlobalFilter={false}
        positionToolbarAlertBanner="none"
        getRowId={(originalRow) => originalRow.id}
      />

      <Divider py={20} size={"lg"} />

      <Button sx={{minWidth:195}} onClick={handleButtonClick}>
        {buttonDisabled ? <Loader color="white" size="sm" /> : "Podpiši in potrdi izbrane"}
      </Button>
    </>
  );
};

export default QuickStatements;
