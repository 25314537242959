import { Grid, Input } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useTranslation } from "react-i18next";

const Personal = ({ handleInputChange, statement }) => {
  const { t } = useTranslation("statement");
  return (
    <>
      <Grid>
        <Grid.Col>
          <h3>{t("personal")}</h3>
        </Grid.Col>

        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Input.Wrapper id="ime-in-p" required label={t("name")} size="md">
            <Input
              id="ime-in-p"
              placeholder={t("name")}
              name="name"
              value={statement.name}
              onChange={handleInputChange}
              required
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <DatePickerInput
            placeholder={t("pickDate")}
            label={t("birthDate")}
            name="birthDate"
            withAsterisk
            value={
              statement.birthDate !== "" ? new Date(statement.birthDate) : null
            }
            onChange={(value: Date) => {
              handleInputChange({
                target: { name: "birthDate", value },
              });
            }}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <DatePickerInput
            placeholder={t("pickDate")}
            label={t("medicalDate")}
            required
            name="doctorCerificateValidUntil"
            value={
              statement.doctorCerificateValidUntil !== ""
                ? new Date(statement.doctorCerificateValidUntil)
                : null
            }
            onChange={(value: Date) => {
              handleInputChange({
                target: { name: "doctorCerificateValidUntil", value },
              });
            }}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Personal;
