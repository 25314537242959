import { useEffect, useMemo, useState } from "react";
import { getAllUserStatements } from "../../api/statements";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { MantineReactTable } from "mantine-react-table";

const AllStatements = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [statements, setStatements] = useState([]);
  const [statement, setStatement] = useState([]);

  function handleClick(statement) {
    setStatement(statement);
  }

  const getStatements = async (token) => {
    setStatements(await getAllUserStatements(token));
  };
  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      await getStatements(token);
    }
    getToken();
  }, [getAccessTokenSilently]);

  const columns = useMemo(
    () => [
      {
        header: "Ime in priimek",
        accessorKey: "name",
      },
      {
        header: "Datum oddaje",
        accessorKey: "dateOfStatement",
      },
    ],
    []
  );

  return (
    <>
      <MantineReactTable
        columns={columns}
        data={statements}
        mantineTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate("/statement-details", { state: row.original });
          },
          sx: {
            cursor: "pointer",
          },
        })}
        //options
        enablePagination
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableRowSelection={false}
        enableColumnOrdering={false}
        enableGlobalFilter
      />
    </>
  );
};

export default AllStatements;
