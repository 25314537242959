import { Grid, Input, Text, Button, Group } from "@mantine/core";
import SignatureCanvas from "react-signature-canvas";
import { useEffect } from "react";

const Instructor = ({ handleInputChange, statement }) => {
  let sigPad: any | null = {};

  const clearSignatureHandler = () => {
    sigPad.clear();
  };

  useEffect(() => {
    sigPad.fromDataURL(statement.leadInstructorSignature, {
      width: 450,
      height: 300,
    });
  });

  return (
    <>
      <Grid>
        <Grid.Col>
          <h3>Podpis učitelja</h3>
        </Grid.Col>

        <Grid.Col lg={4} md={4} sm={12} xs={12}>
          <Input.Wrapper
            id="leadInstructorName"
            required
            label="Učitelj padalstva"
            size="md"
          >
            <Input
              id="leadInstructorName"
              placeholder="Aleš Debeljak"
              name="leadInstructorName"
              value={statement.leadInstructorName}
              onChange={handleInputChange}
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col lg={8} md={8} sm={12} xs={12}>
          <Text>Podpis:</Text>
          <Group>
            <SignatureCanvas
              penColor="black"
              backgroundColor="lightgray"
              ref={(ref) => {
                sigPad = ref;
              }}
              name="leadInstructorSignature"
              canvasProps={{ width: 450, height: 300, className: "sigCanvas" }}
              onEnd={({ srcElement }) => {
                if (srcElement instanceof HTMLCanvasElement) {
                  const value = srcElement.toDataURL();
                  handleInputChange({
                    target: { name: "leadInstructorSignature", value },
                  });
                }
              }}
            />

            <Button style={{ marginTop: 25 }} onClick={clearSignatureHandler}>
              Počisti podpis
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Instructor;
