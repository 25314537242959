import { Input } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

const School = ({
  student,
  handleInputChange,
  handleDatePickerInputChange,
}) => {
  return (
    <>
      <Input.Wrapper label="Status" size="md">
        <Input
          placeholder="Status"
          value={student.status !== null ? student.status : ""}
          name="status"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Zaporedna št." size="md">
        <Input
          placeholder="Zaporedna št."
          value={student.sequential !== null ? student.sequential : ""}
          name="sequential"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Dosje" size="md">
        <Input
          placeholder="Dosje"
          value={student.dossier !== null ? student.dossier : ""}
          name="dossier"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <DatePickerInput
        placeholder="Izberi datum"
        label="Začetek šolanja"
        name="started"
        value={student.started ? new Date(student.started) : null}
        onChange={(value) => handleDatePickerInputChange("started", value)}
      />

      <DatePickerInput
        placeholder="Izberi datum"
        label="Zaključek šolanja"
        name="finished"
        value={student.finished ? new Date(student.finished) : null}
        onChange={(value) => handleDatePickerInputChange("finished", value)}
      />

      <DatePickerInput
        placeholder="Izberi datum"
        label="Praktični preizkus"
        name="practical"
        value={student.practical ? new Date(student.practical) : null}
        onChange={(value) => handleDatePickerInputChange("practical", value)}
      />

      <Input.Wrapper label="Preteklo usposabljanje" size="md">
        <Input
          placeholder="Preteklo usposabljanje"
          value={student.previousSchool !== null ? student.previousSchool : ""}
          name="previousSchool"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Št. dosjeja v prejšnji padalski šoli" size="md">
        <Input
          placeholder="Št. dosjeja v prejšnji padalski šoli"
          value={
            student.previousSchoolDossier !== null
              ? student.previousSchoolDossier
              : ""
          }
          name="previousSchoolDossier"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Št. skokov v prejšnji padalski šoli" size="md">
        <Input
          placeholder="Št. skokov v prejšnji padalski šoli"
          value={
            student.previousSchoolJumps !== null
              ? student.previousSchoolJumps
              : ""
          }
          name="previousSchoolJumps"
          onChange={handleInputChange}
        />
      </Input.Wrapper>

      <DatePickerInput
        placeholder="Izberi datum"
        label="Izpit"
        name="exam"
        value={student.exam ? new Date(student.exam) : null}
        onChange={(value) => handleDatePickerInputChange("exam", value)}
      />

      <DatePickerInput
        placeholder="Izberi datum"
        label="Izpit CAA"
        name="examCaa"
        value={student.examCaa ? new Date(student.examCaa) : null}
        onChange={(value) => handleDatePickerInputChange("examCaa", value)}
      />

      <DatePickerInput
        placeholder="Izberi datum"
        label="Ponovitev izrednih postopkov"
        name="emergencyProcedures"
        value={
          student.emergencyProcedures
            ? new Date(student.emergencyProcedures)
            : null
        }
        onChange={(value) =>
          handleDatePickerInputChange("emergencyProcedures", value)
        }
      />

      <DatePickerInput
        placeholder="Izberi datum"
        label="Datum izpisa iz šole"
        name="exited"
        value={student.exited ? new Date(student.exited) : null}
        onChange={(value) => handleDatePickerInputChange("exited", value)}
      />
    </>
  );
};

export default School;
