import axios from "axios";
import { Student } from "../types";

const BASE_URI = process.env.REACT_APP_BASE_API_URI || "http://localhost:3000";

export const getAllStudents = async (
  token: string | null
): Promise<Student[]> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/students`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getLatestStudents = async (
  token: string | null
): Promise<Student[]> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/students/latest`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getSingleStudent = async (
  token: string | null,
  id: string | undefined
): Promise<Student> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/students/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getSingleStudentMedical = async (
  token: string | null,
  id: string | undefined
): Promise<string> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/students/${id}/medical`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const saveStudentMedical = async (
  token: string | null,
  formData: FormData,
  id: string | undefined
): Promise<any> => {
  try {
    const response = await axios.patch(
      `${BASE_URI}/v1/students/${id}/medical`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteStudentMedical = async (
  token: string | null,
  id: string
) => {
  const response = await axios({
    method: "DELETE",
    url: `${BASE_URI}/v1/students/${id}/medical`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.status;
};

export const updateStudent = async (
  token: string | null,
  payload: Student,
  id: string | undefined
): Promise<Student> => {
  const response = await axios({
    method: "PATCH",
    url: `${BASE_URI}/v1/students/${id}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const deleteStudent = async (id: string, token: string | null) => {
  const response = await axios({
    method: "DELETE",
    url: `${BASE_URI}/v1/students/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.status;
};

export const createStudent = async (
  token: string | null,
  payload: any
): Promise<number> => {
  const response = await axios({
    method: "POST",
    url: `${BASE_URI}/v1/students`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  });

  return response.status;
};
