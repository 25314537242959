import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Center,
  Divider,
  Group,
  Input,
  Loader,
  LoadingOverlay,
  Modal,
  Title,
} from "@mantine/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteTandemStatement,
  getSingleTandemStatement,
  updateTandemStatement,
} from "../../../../api/tandemStatements";
import { TandemStatementType } from "../../../../types";
import SignatureModule from "../../../global/SignatureModule";
import Personal from "../../statement/sections/Personal";
import Address from "../../statement/sections/Address";
import Legal from "../../statement/sections/Legal";

const TandemStatementDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { state } = useLocation();
  const id = state.id;
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState("");
  const [tandemStatement, setTandemStatement] = useState<TandemStatementType>({
    id: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    street: "",
    postcode: "",
    city: "",
    country: "",
    legalGuardian: "",
    placeOfStatement: "",
    statementSignature: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      getTandemStatement(token);
    }
    getToken();
    setIsLoading(false);
  }, [getAccessTokenSilently]);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setTandemStatement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const getTandemStatement = async (token) => {
    const tandemStatement = await getSingleTandemStatement(token, id);
    setTandemStatement(tandemStatement);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = async () => {
    await handleDelete(tandemStatement.id);
    navigate(-1);
    setShowConfirmation(false);
  };

  const handleDelete = async (id) => {
    deleteTandemStatement(id, accessToken);
  };

  async function handleSubmit(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    setButtonDisabled(true);

    const response = await updateTandemStatement(
      accessToken,
      tandemStatement,
      tandemStatement.id
    );
    navigate(-1);
    return response;
  }

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} overlayBlur={2} />;
  }

  return (
    <>
      <Title>
        {tandemStatement.name} {tandemStatement.surname}
      </Title>
      <form onSubmit={handleSubmit}>
        <Personal
          tandemStatement={tandemStatement}
          handleInputChange={handleInputChange}
        />

        <Address
          tandemStatement={tandemStatement}
          handleInputChange={handleInputChange}
        />

        <Legal
          tandemStatement={tandemStatement}
          handleInputChange={handleInputChange}
        />

        <Input.Wrapper label="Datum podpisa" size="md">
          <Input
            value={new Date(tandemStatement.createdAt).toDateString()}
            name="createdAt"
            onChange={handleInputChange}
          />
        </Input.Wrapper>

        <Divider size="lg" my="lg" />

        <Group spacing="xl">
          <Button
            type="submit"
            color="blue"
            disabled={buttonDisabled ? true : false}
          >
            {buttonDisabled ? <Loader size="sm" /> : <p>Shrani</p>}
          </Button>
          <Button
            color={"red"}
            onClick={() => {
              setShowConfirmation(true);
            }}
          >
            Izbriši izjavo
          </Button>
        </Group>

        <Modal
          opened={showConfirmation}
          onClose={handleCancel}
          size="sm"
          title="Brišem izjavo?"
        >
          <Center>
            <Group>
              <Button key="Prekliči" onClick={handleCancel} color="red">
                Prekliči
              </Button>
              <Button key="Potrdi" onClick={handleConfirm}>
                Potrdi
              </Button>
            </Group>
          </Center>
        </Modal>
      </form>
    </>
  );
};

export default TandemStatementDetails;
