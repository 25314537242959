import { Title, Text } from '@mantine/core'
import classes from "./StatementSuccess.module.css"

const StatementSuccess = () => {
  return (
    <div className={classes.container}>
      <Title order={1} style={{ marginTop: 10, textAlign: "center" }}>
        Prosti
        <Text color="orange" inherit component="span">
          pad
        </Text>
      </Title>
      <Title order={1} style={{ textAlign: 'center', marginTop: '1rem' }}>Uspešno oddana izjava!</Title>
    </div>
  )
}

export default StatementSuccess
