import axios from "axios";
import { TandemStatementType } from "../types";

const BASE_URI = process.env.REACT_APP_BASE_API_URI || "http://localhost:3000";

export const getAllTandemStatements = async (
  token: string | null
): Promise<TandemStatementType[]> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/tandem/statements`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getLatestTandemStatements = async (
  token: string | null
): Promise<TandemStatementType[]> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/tandem/statements/latest`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getSingleTandemStatement = async (
  token: string | null,
  id: string | undefined
): Promise<TandemStatementType> => {
  const response = await axios({
    method: "GET",
    url: `${BASE_URI}/v1/tandem/statements/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const updateTandemStatement = async (
  token: string | null,
  payload: TandemStatementType,
  id: string | undefined
): Promise<TandemStatementType> => {
  const response = await axios({
    method: "PATCH",
    url: `${BASE_URI}/v1/tandem/statements/${id}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const deleteTandemStatement = async (
  id: string,
  token: string | null
) => {
  const response = await axios({
    method: "DELETE",
    url: `${BASE_URI}/v1/tandem/statements/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.status;
};

export const createTandemStatement = async (payload: any): Promise<number> => {
  const response = await axios({
    method: "POST",
    url: `${BASE_URI}/v1/tandem/statements`,
    data: payload,
  });

  return response.status;
};
