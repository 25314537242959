import { Text, List } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Texts = () => {
  const { t } = useTranslation("statement");
  return (
    <>
      <Text weight={700}>{t("text.text_one")}</Text>
      <Text size="md">{t("text.text_two")}</Text>
      <List type="ordered" withPadding>
        <List.Item>{t("text.list_one_text_one")}</List.Item>
        <List.Item>{t("text.list_one_text_two")}</List.Item>
        <List.Item>{t("text.list_one_text_three")}</List.Item>
        <List.Item>{t("text.list_one_text_four")}</List.Item>
      </List>

      <Text size="md">&nbsp;</Text>
      <Text size="md">{t("text.text_three")}</Text>
      <List type="ordered" withPadding>
        <List.Item>{t("text.list_two_text_one")}</List.Item>
        <List.Item>{t("text.list_two_text_two")}</List.Item>
        <List.Item>{t("text.list_two_text_three")}</List.Item>
      </List>

      <Text size="md">&nbsp;</Text>
      <Text size="md">{t("text.text_four")}</Text>

      <Text size="md">&nbsp;</Text>
      <Text size="md">{t("text.text_five")}</Text>
    </>
  );
};

export default Texts;
