import { Button, Title } from "@mantine/core";
import SignatureCanvas from "react-signature-canvas";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SignatureModule = ({ handleInputChange, signature }) => {
  const { t } = useTranslation("statement");
  let sigPad: any | null = {};
  const clearSignatureHandler = () => {
    sigPad.clear();
    const value = sigPad.toDataURL();
    handleInputChange({
      target: { name: "statementSignature", value },
    });
  };

  useEffect(() => {
    sigPad.fromDataURL(signature, {
      width: 450,
      height: 300,
    });
  });

  return (
    <>
      <Title py={15} order={4}>
        {t("signature")}
      </Title>

      <SignatureCanvas
        penColor="black"
        backgroundColor="lightgray"
        ref={(ref) => {
          sigPad = ref;
        }}
        clearOnResize={true}
        name="statementSignature"
        canvasProps={{ width: 450, height: 300, className: "sigCanvas" }}
        onEnd={({ srcElement }) => {
          if (srcElement instanceof HTMLCanvasElement) {
            const value = srcElement.toDataURL();
            handleInputChange({
              target: { name: "statementSignature", value },
            });
          }
        }}
      />

      <Button onClick={clearSignatureHandler} style={{ marginTop: 25 }}>
        {t("clearSignature")}
      </Button>
    </>
  );
};

export default SignatureModule;
