import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Routes, Route, Navigate } from "react-router-dom";
import StatementSuccess from "./components/global/StatementSuccess";
import Statements from "./components/statements/Statements";
import Statement from "./components/statements/statement/Statement";
import Layout from "./components/global/layout/Layout";
import AllStatements from "./components/statements/AllStatements";
import EditStatement from "./components/statements/details/Details";
import Students from "./components/students/Students";
import Latest from "./components/students/Latest";
import StudentDetails from "./components/students/details/StudentDetails";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import Unauthorized from "./components/global/Unauthorized";
import { LoadingOverlay } from "@mantine/core";
import { TandemStatements } from "./components/tandems/statements/TandemStatements";
import TandemStatementDetails from "./components/tandems/statements/details/TandemStatementDetails";
import QuickStatements from "./components/statements/QuickStatements";
import TandemStatement from "./components/tandems/statement/TandemStatement";

const ProtectedStatements = withAuthenticationRequired(Statements);
const ProtectedAllStatements = withAuthenticationRequired(AllStatements);
const ProtectedStatementDetails = withAuthenticationRequired(EditStatement);
const ProtectedStudents = withAuthenticationRequired(Students);
const ProtectedLatestStudents = withAuthenticationRequired(Latest);
const ProtectedStudentDetails = withAuthenticationRequired(StudentDetails);
const ProtectedQuickStatements = withAuthenticationRequired(QuickStatements);

const ProtectedTandemStatements = withAuthenticationRequired(TandemStatements);
const ProtectedTandemStatementDetails = withAuthenticationRequired(
  TandemStatementDetails
);

const App = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken = jwt_decode(token);
        const roles = decodedToken["permissions"] || [];
        setUserRoles(roles);
        localStorage.setItem("userRoles", JSON.stringify(roles));
      } catch (error) {
        console.error(error);
      }
    };

    if (isAuthenticated) {
      getRoles();
    } else {
      const roles = JSON.parse(localStorage.getItem("userRoles") || "[]");
      setUserRoles(roles);
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (isLoading) {
    return <LoadingOverlay visible={true} overlayBlur={2} />;
  }

  const hasPermissions = (role: string) => {
    return userRoles.includes(role) ? true : false;
  };

  return (
    <>
      <Routes>
        <Route
          path="/statements/latest"
          element={
            hasPermissions("write:statements") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedStatements />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />
        <Route
          path="/statements"
          element={
            <Layout hasPermissions={hasPermissions}>
              <ProtectedAllStatements />
            </Layout>
          }
        />
        <Route
          path="/statement-details"
          element={
            hasPermissions("write:statements") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedStatementDetails />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />

        <Route
          path="/"
          element={
            hasPermissions("write:statements") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedQuickStatements />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />

        <Route
          path="/students"
          element={
            hasPermissions("write:students") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedStudents />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />
        <Route
          path="/students/latest"
          element={
            hasPermissions("write:students") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedLatestStudents />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />
        <Route
          path="/student-details"
          element={
            hasPermissions("write:students") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedStudentDetails />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />
        <Route
          path="/tandems/statements"
          element={
            hasPermissions("write:tandems") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedTandemStatements />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />
        <Route
          path="/tandems/statement-details"
          element={
            hasPermissions("write:tandems") ? (
              <Layout hasPermissions={hasPermissions}>
                <ProtectedTandemStatementDetails />
              </Layout>
            ) : (
              <Navigate to="/unauthorized" replace />
            )
          }
        />
        <Route path="/statement" element={<Statement />} />
        <Route path="/tandem-statement" element={<TandemStatement />} />
        <Route path="/statement-success" element={<StatementSuccess />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </>
  );
};

export default App;
