import {
  Alert,
  Button,
  Center,
  Divider,
  Loader,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import classes from "../../statements/statement/Statement.module.css";
import { TandemStatementType } from "../../../types";
import { createTandemStatement } from "../../../api/tandemStatements";
import Personal from "./sections/Personal";
import Address from "./sections/Address";
import Legal from "./sections/Legal";
import Initial from "./sections/Initial";

const TandemStatement = () => {
  const { t, i18n } = useTranslation("tandemStatement");
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const [tandemStatement, setTandemStatement] = useState<TandemStatementType>({
    name: "",
    surname: "",
    email: "",
    phone: "",
    street: "",
    postcode: "",
    city: "",
    country: "Slovenija",
    legalGuardian: "",
    placeOfStatement: "",
    statementSignature: "",
  });

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setTandemStatement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setButtonDisabled(true);

    try {
      await createTandemStatement(tandemStatement);
      navigate("/statement-success");
    } catch (error) {
      setButtonDisabled(false);
      window.scrollTo(0, 0);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  return (
    <div className={classes.main_container}>
      <Title order={1} style={{ marginTop: 10, textAlign: "center" }}>
        Prosti
        <Text color="orange" inherit component="span">
          pad
        </Text>
      </Title>

      <Paper shadow="xl" p="xl" style={{ marginTop: 10, marginBottom: 50 }}>
        <Center>
          {i18n.language === "sl" && (
            <Button color="orange" onClick={() => handleLanguageChange("en")}>
              English
            </Button>
          )}
          {i18n.language === "en" && (
            <Button color="orange" onClick={() => handleLanguageChange("sl")}>
              Slovensko
            </Button>
          )}
        </Center>
        <form onSubmit={handleSubmit}>
          <h2 style={{ textAlign: "center" }}>{t("title")} </h2>
          {showError && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Napaka"
              color="red"
            >
              {t("error")}
            </Alert>
          )}

          <Divider size="md" my="md" />
          <Text weight={700}>{t("introduction")}</Text>
          <Divider size="sm" my="sm" />

          <Personal
            tandemStatement={tandemStatement}
            handleInputChange={handleInputChange}
          />

          <Divider size="sm" my="sm" />

          <Address
            tandemStatement={tandemStatement}
            handleInputChange={handleInputChange}
          />

          <Divider size="lg" my="lg" />

          <Initial text={t("text.text_one")} />

          <Divider size="sm" my="sm" />
          <Initial text={t("text.text_two")} />

          <Divider size="sm" my="sm" />
          <Initial
            text={t("text.text_three")}
            list={t("text.text_three_items", { returnObjects: true })}
          />

          <Divider size="sm" my="sm" />
          <Initial text={t("text.text_four")} />

          <Divider size="sm" my="sm" />
          <Initial
            text={t("text.text_five")}
            list={t("text.text_five_items", { returnObjects: true })}
          />

          <Divider size="sm" my="sm" />
          <Initial text={t("text.text_six")} />

          <Divider size="sm" my="sm" />
          <Initial text={t("text.text_seven")} />

          <Divider size="sm" my="sm" />
          <Initial optional={true} text={t("text.text_eight")} />

          <Divider size="sm" my="sm" />
          <Legal
            tandemStatement={tandemStatement}
            handleInputChange={handleInputChange}
          />
          <Divider size="sm" my="sm" />

          <Button
            type="submit"
            color="orange"
            disabled={buttonDisabled ? true : false}
          >
            {buttonDisabled ? <Loader size="sm" /> : <p>{t("submit")}</p>}
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default TandemStatement;
