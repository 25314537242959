import {
  AppShell,
  Navbar,
  Header,
  UnstyledButton,
  Group,
  ThemeIcon,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  ActionIcon,
} from "@mantine/core";
import { User } from "./User";
import { Link, useNavigate } from "react-router-dom";
import {
  IconAlertCircle,
  IconArrowLeft,
  IconDatabase,
  IconSchool,
} from "@tabler/icons-react";
import { useState } from "react";

interface MainLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  to: string;
}

function MainLink({ icon, color, label, to }: MainLinkProps) {
  return (
    <Link to={to}>
      <UnstyledButton
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.black,
          "&:hover": {
            backgroundColor: theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>

          <Text size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    </Link>
  );
}

const Layout = ({ children, hasPermissions }) => {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <AppShell
        padding="md"
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          <Navbar
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 300 }}
          >
            <Navbar.Section grow mt="xs">
              {hasPermissions("write:statements") && (
                <MainLink
                  icon={<IconAlertCircle size="1rem" />}
                  color="orange"
                  label="Izjave - hitro"
                  to="/"
                />
              )}
              {hasPermissions("write:statements") && (
                <MainLink
                  icon={<IconAlertCircle size="1rem" />}
                  color="orange"
                  label="Zadnje izjave"
                  to="/statements/latest"
                />
              )}
              {hasPermissions("write:statements") && (
                <MainLink
                  icon={<IconDatabase size="1rem" />}
                  color="teal"
                  label="Vse izjave"
                  to="/statements"
                />
              )}
              {hasPermissions("read:students") && (
                <MainLink
                  icon={<IconSchool size="1rem" />}
                  color="blue"
                  label="Zadnji učenci"
                  to="/students/latest"
                />
              )}
              {hasPermissions("read:students") && (
                <MainLink
                  icon={<IconSchool size="1rem" />}
                  color="blue"
                  label="Učenci"
                  to="/students"
                />
              )}
              {hasPermissions("write:tandems") && (
                <MainLink
                  icon={<IconDatabase size="1rem" />}
                  color="teal"
                  label="Tandem izjave"
                  to="/tandems/statements"
                />
              )}
            </Navbar.Section>
            <Navbar.Section>
              <User />
            </Navbar.Section>
          </Navbar>
        }
        header={
          <Header p="md" height={{ base: 50, md: 60 }}>
            <Group sx={{ height: "100%" }} px={20} position="apart">
              <div>
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>
              </div>
              <ActionIcon
                onClick={goBack}
                aria-label="Back"
                variant="transparent"
              >
                <IconArrowLeft size="2rem" />
              </ActionIcon>
            </Group>
          </Header>
        }
        styles={(theme) => ({
          main: {
            backgroundColor: theme.colors.gray[0],
          },
        })}
      >
        {children}
      </AppShell>
    </>
  );
};

export default Layout;
