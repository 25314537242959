import { Grid, Input, Radio } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useTranslation } from "react-i18next";

const Equipment = ({ handleInputChange, statement }) => {
  const { t } = useTranslation("statement");
  return (
    <>
      <h3>{t("equipment")}</h3>

      <Grid>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Radio.Group
            label={t("ownEquipment")}
            size="md"
            required
            name="ownEquipment"
            value={statement.ownEquipment === "true" ? "true" : "false"}
            onChange={(value: string) => {
              handleInputChange({
                target: { name: "ownEquipment", value },
              });
            }}
          >
            <Radio name="ownEquipment" value="true" label={t("yes")} />
            <Radio name="ownEquipment" value="false" label={t("no")} />
          </Radio.Group>
        </Grid.Col>
      </Grid>

      {statement.ownEquipment === "true" && (
        <>
          <h4>{t("mainCanopy")}</h4>
          <Grid>
            <Grid.Col lg={6} md={6} sm={12} xs={12}>
              <Input.Wrapper
                id="mod-in-vel-pad"
                required
                label={t("mainModelSize")}
                size="md"
              >
                <Input
                  id="mod-in-vel-pad"
                  placeholder={t("mainModelSize")}
                  name="mainParachuteModelAndSize"
                  value={statement.mainParachuteModelAndSize}
                  onChange={handleInputChange}
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>

          <h4>{t("reserveCanopy")}</h4>

          <Grid>
            <Grid.Col lg={6} md={6} sm={12} xs={12}>
              <Input.Wrapper
                id="mod-in-vel-rez-pad"
                required
                label={t("mainModelSize")}
                size="md"
              >
                <Input
                  id="mod-in-vel-rez-pad"
                  placeholder={t("mainModelSize")}
                  name="spareParachuteModelAndSize"
                  value={statement.spareParachuteModelAndSize}
                  onChange={handleInputChange}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col lg={6} md={6} sm={12} xs={12}>
              <DatePickerInput
                placeholder={t("pickDate")}
                label={t("licenseValid")}
                name="spareParachuteValidUntil"
                value={
                  statement.spareParachuteValidUntil !== ""
                    ? new Date(statement.spareParachuteValidUntil)
                    : new Date()
                }
                onChange={(value: Date) => {
                  handleInputChange({
                    target: { name: "spareParachuteValidUntil", value },
                  });
                }}
              />
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
};

export default Equipment;
